
import { mapGetters } from 'vuex'
  export default {
    computed: {
      ...mapGetters({
        company: 'profile/GET_COMPANY',
      })
    },
  }
  

