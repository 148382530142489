export default {
    SET_STEPS(state, { initsteps }) {
        state.progressBar = initsteps
    },

    SET_TITLE(state, pageTitle) {
        state.progressBar.pageTitle = pageTitle
    },

    UPDATE_CURRENT_STEPINDEX(state, { stepIndex, title }) {
        state.progressBar.currentStepIndex = stepIndex
        state.progressBar.pageTitle = title
    },

    UPDATE_STEPINDEX(state, { stepIndex, title }) {
        state.progressBar.currentStepIndex = stepIndex
        state.progressBar.pageTitle = title
    },

    UPDATE_STEPCOMPLETED(state, nextSteps) {
        state.progressBar.nextStepIndex = nextSteps

        // state.progressBar.forEach(item => {
        //     item.completed = item.steps < item.currentStepIndex
        //   })
    },

    UPDATE_VALUE(state, {totalActions, nextValue}) {
        const roundupTotal = totalActions.length - 1
        if(nextValue.length <= roundupTotal) {
            state.value = ((nextValue.length) / (roundupTotal)) * 100
        }
    }
}