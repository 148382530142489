export default {
    SET_INPROCESS_STEP({commit},step){
        commit('SET_INPROCESS_STEP',step)
    },
    SET_YOTIURL({commit},url){
        commit('SET_YOTIURL',url)
    },
    SET_QR({commit},code){
        commit('SET_QR',code)
    },
    SET_TOKEN({commit},sessionToken){
        commit('SET_TOKEN',sessionToken)
    },
    SET_USE_COMPUTER({commit},useComputer){
        commit('SET_USE_COMPUTER',useComputer)
    },
    SET_SELECT_PERSON({commit},selectPerson){
        commit('SET_SELECT_PERSON',selectPerson)
    },
    RESETFIELD({ commit }, logout) {
        commit('RESET_FIELD', logout)
      },
}
