import clearLocalStorageMixin from '~/mixins/clearLocalStorageMixin'
export default function ({
  app,
  route,
  redirect,
  store,
  $auth,error
}) {
  const isAuthenticated = !!store.state.auth.user
  const isToken = localStorage.getItem('auth._token.local')
  const linkCode = localStorage.getItem('linkCode')
  const slug = localStorage.getItem('slug')
  let vuex = JSON.parse(localStorage.getItem('vuex'))
  vuex = vuex.profile.company
  if (isToken === 'false' || isToken === null) {

    $auth.logout()
    const vuex = JSON.parse(localStorage.getItem('vuex'))
    store.commit('profile/RESET_FIELD', vuex.profile.company)
    store.commit('stepper/RESET_FIELD', vuex.stepper)
    clearLocalStorageMixin.methods.clearLocalStorageExceptKey('slug')
    if(linkCode === 'undefined' || !linkCode){
      return error({
        statusCode: 404,
        message: 'Portal link is invalid, please visit again',
      })
    }
    redirect(`/portal/find-company/${slug}/${linkCode}`)
  }  else if (vuex.hasCompany && vuex.statement_declared && !vuex.role) {
    redirect(`/portal/my-companies/${slug}`)
  }
}
