export default {
   
    SET_SELECT_ENTITIES({commit},selectEntities){
        commit('SET_SELECT_ENTITIES',selectEntities)
    },
    
    SET_RESULT({commit},result){
        commit('SET_RESULT',result)
    },
    SET_RELATED_ENTITIES({commit}, relatedEntities) {
        commit('SET_RELATED_ENTITIES', relatedEntities)
    }
}