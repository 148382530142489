export const state = () => ({
  header: null
})

export const getters = {
  getHeader(state) {
    return state.header
  }
}

export const mutations = {
  setHeader(state, header) {
    state.header = header
  }
}

export const actions = {
  clear({commit}) {
    commit('setHeader', null)
  },
}
