export default function ({ redirect, route, store }) {
  const isMaintenance = store.app.$config.maintenanceMode
  if (isMaintenance && !route.path.includes('/portal/down-for-maintenance')) {
    redirect('/portal/down-for-maintenance')
  }
  if (
    isMaintenance === false &&
    route.path.includes('/portal/down-for-maintenance')
  ) {
    redirect('/portal/find-company/' + localStorage.getItem('slug'))
  }
}
