import clearLocalStorageMixin from '~/mixins/clearLocalStorageMixin'
export default async function ({
  app,
  route,
  redirect,
  store,
  $auth
}) {
  const isAuthenticated = !!store.state.auth.user
  localStorage.setItem('slug', route.params.slug)
  store.dispatch('tenant/clear')
  const isToken = localStorage.getItem('auth._token.local')
  let role = JSON.parse(localStorage.getItem('vuex'))
  role = role.profile.company.role
  const linkCode = store.getters['profile/GET_COMPANY'].linkCode
  const companyCode = route.name !== 'portal-my-companies-slug' ? route.name === 'portal-find-company-slug-company-questions-code' ? `/${route.params.code}` : `/${linkCode}` : ''
  if (isToken !== 'false' && isToken !== null) {

    if (isAuthenticated) {
      try {
        const response = await app.$axios.get(
          '/auth/enrich-link/' + route.params.slug + companyCode
        )

        // Store require action for invited user

        const companyDetails = store.getters['profile/GET_COMPANY']
        if (route.name === 'portal-slug-code' && response.data.user.requires_input.length > 0 && Array.isArray(companyDetails.requiredCompanyActions) && !companyDetails.requiredCompanyActions.some(
          checkAction => checkAction.companyLinkCode === response.data.user.portal_link_code
        )) {
          const requiredCompanyActions = {
            'companyLinkCode': response.data.user.portal_link_code,
            'requires_input': response.data.user.requires_input
          }
          store.dispatch('profile/SET_REQUIRED_ACTION',requiredCompanyActions)
        }


        if (role === 'identity') {
          redirect(`/portal/identity/${route.params.slug}/match/${linkCode}`)
        } else if (response.data.user.has_company === false) {
            if (response.data.user.statement_enabled  && !response.data.user.statement_declared) {
              redirect('/portal/find-company/' + route.params.slug + '/declaration')
            } else if (response.data.user.soletrader_enabled) {
                redirect(`/portal/find-company/${route.params.slug}/select-business-type`)
            }
            redirect(`/portal/find-company/${route.params.slug}/find-company-account-created`)
        } else if (response.data.user.statement_enabled  && !response.data.user.statement_declared) {
          redirect('/portal/find-company/' + route.params.slug + '/declaration')
        } else {
          const companyPaths = response.data.user.company.portal_option_paths
          const companyAnswersLength = companyPaths ? companyPaths.answers.length : 0
          const configurationId = response.data.user.company.configuration_id
          const pathUuid = store.getters['profile/GET_COMPANY'].Uuid
          const hasQuestion = response.data.user.has_config_options
          if (
            (companyAnswersLength === 0 && configurationId === null && companyPaths !== null && !pathUuid && hasQuestion) &&
            (route.name !== 'portal-my-companies-slug' &&
              route.name !== 'portal-find-company-slug-company-questions-code')
          ) {
            redirect(`/portal/find-company/${route.params.slug}/company-questions/${response.data.user.portal_link_code}`)
          } else if (
            route.name === 'portal-find-company-slug-company-questions-code' &&
            (companyAnswersLength > 0 || configurationId || companyPaths == null || pathUuid || !hasQuestion)
          ) {
            redirect(`/portal/my-companies/${route.params.slug}`)
          }
        }
      } catch (error) {
        if (error && error.response && (error.response.status === 401 || error.response.status === 500)) {
          const slug = localStorage.getItem('slug')
          const vuex = JSON.parse(localStorage.getItem('vuex'))
          store.commit('profile/RESET_FIELD', vuex.profile.company)
          store.commit('stepper/RESET_FIELD', vuex.stepper)
          clearLocalStorageMixin.methods.clearLocalStorageExceptKey('slug')
          localStorage.setItem('isUnAuthicated', true)
          await $auth.logout()
          redirect(`/portal/find-company/${slug}`)
        }

      }
    }
  } else {
    const slug = localStorage.getItem('slug')
    if (isAuthenticated) {
      await $auth.logout()
    }
    const vuex = JSON.parse(localStorage.getItem('vuex'))
    store.commit('profile/RESET_FIELD', vuex?.profile?.company)
    clearLocalStorageMixin.methods.clearLocalStorageExceptKey('slug')
    redirect(`/portal/find-company/${slug}`)
  }
}
