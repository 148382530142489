
export default {
  SET_INPROCESS_STEP(state,step){
    state.inProcessStep = step
  },
  SET_YOTIURL(state,url){
    state.yotiIframeUrl = url
  },
  SET_QR(state,code){
    state.QRCode = code
  },
  SET_TOKEN(state,sessionToken){
    state.sessionToken = sessionToken
  },
  SET_USE_COMPUTER(state,useComputer){
    state.useComputer = useComputer
  },
  SET_SELECT_PERSON(state,selectPerson){
    state.selectPerson = selectPerson
  },
  RESET_FIELD(state, value) {
    Object.keys(state).forEach((key) => {
      state.inProcessStep = ''
      state.yotiIframeUrl = ''
      state.QRCode = ''
      state.sessionToken = ''
      state.useComputer = false
      state.selectPerson = ''
    })
  },
}
