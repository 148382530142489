export default {
    GET_SELECTCOMPANY:(state)=>{
        return state.selectcompany
    },
    GET_SELECTLOOKUP:(state)=>{
      return state.selectLookupId
    },
    GET_SELECTCOUNTRY:(state)=>{
        return state.selectCountryCode
    },
    GET_MANUALCOMPANY:(state)=>{
        return state.manualCompany
    },
    GET_SEARCHCOMPANY:(state)=>{
        return state.searchedcompany
    },
}
