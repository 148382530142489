import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/fr'
import 'vue2-datepicker/locale/en'
import 'vue2-datepicker/locale/es'
import 'vue2-datepicker/locale/pt'
import 'vue2-datepicker/locale/de'
import 'vue2-datepicker/locale/it'
Vue.use(DatePicker)
