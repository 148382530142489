
export default {
    name: 'CookieBanner',

    data() {
        return {
            showCookieConsent: false
        }
    },

    mounted() {
        // check cookies
        const selected = this.checkCookie('cookies_selected')
        if (!selected) {
            this.showCookieConsent = true
        } else {
            this.setCookie('cookies_selected', true, 1)
            this.setCookie('allowThirdPartyCookies',  this.checkCookie('allowThirdPartyCookies'), 1)
        }

        this.loadThirdPartyServices()
    },

    methods: {
        checkCookie(cookieName) {
            const cookies = document.cookie.split(';')
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim()
                if (cookie.startsWith(cookieName + '=')) {
                    return cookie.substring(cookieName.length + 1)
                }
            }
            return false
        },

        loadThirdPartyServices() {
            const allowThirdPartyCookies = this.checkCookie('allowThirdPartyCookies')
            // Create the element
            if (this.$config.environment === 'production' && allowThirdPartyCookies === 'true') {
                let key = ''
                const script = document.createElement("script")
                key = this.$config.heapProductionKey
                // Add script content
                script.innerHTML = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                heap.load(${key})`
                document.head.appendChild(script)
            }
        },

        setCookie(name, value, days) {
            let expires = ""
            if (days) {
                const date = new Date()
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
                expires = "; expires=" + date.toUTCString()
            }
            document.cookie = name + "=" + value + expires + "; path=/"
        },

        denyCookie() {
            this.setCookie('allowThirdPartyCookies', false, 1)
            this.setCookie('cookies_selected', true, 1)
            this.showCookieConsent = false
        },

        acceptCookie() {
            this.setCookie('allowThirdPartyCookies', true, 1)
            this.setCookie('cookies_selected', true, 1)
            this.showCookieConsent = false
            this.loadThirdPartyServices()
        }
    }
}
