
import VueToastr from './Toastr.vue'

VueToastr.install = (Vue, options = {}) => {
  // Create component instance
  const MyComponent = Vue.extend({
    render: (h) => {
      return h(VueToastr, {
        props: {
          options,
        },
        ref: 'vueToastr',
      })
    },
  })
  // or, render off-document and append afterwards:
  const component = new MyComponent().$mount()
  document.body.appendChild(component.$el)
  // 4. add an instance method
  Vue.prototype.$notification = component.$refs.vueToastr
}
// Install by default if using the script tag
// equal to Vue.use(window.vueToastr)
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(VueToastr)
}
export default VueToastr
