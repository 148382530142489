export default {
  SET_LOGOURL(state, url) {
    state.company.logo = url
  },
  SET_NAME(state, name) {
    state.company.name = name
  },
  SET_ADDRESS(state, address) {
    state.company.address = address
  },
  SET_COMPANYNAME(state, companyName) {
    state.company.companyName = companyName
  },
  SET_COMPANYDETAILS(state, company) {
    state.company = company
  },
  SET_EMAIL(state, supportEmail) {
    state.company.supportEmail = supportEmail
  },
  SET_COMPANYADDED(state, hasCompany) {
    state.company.hasCompany = hasCompany
  },
  SET_IDSTATUS(state, idStatus) {
    state.company.idStatus = idStatus
  },
  SET_LINKCODE(state, linkCode) {
    state.company.linkCode = linkCode
  },
  SET_ROLE(state, role) {
    state.company.role = role
  },
  SET_BG_COLOR(state, backgroundColor) {
    state.company.backgroundColor = backgroundColor
  },
  SET_TEXT_COLOR(state, textColor) {
    state.company.textColor = textColor
  },
  SET_CTA_COLOR(state, ctaColor) {
    state.company.ctaColor = ctaColor
  },
  SET_CUSTOMER_REF(state, customerReference) {
    state.company.customerReference = customerReference
  },
  SET_STATEMENT (state, statement) {
    state.company.statement = statement
  },
  SET_STATEMENT_DECLARED (state, statementDeclared) {
    state.company.statement_declared = statementDeclared
  },
  SET_COMPANYDOMAIN(state,domain){
    state.company.companyDomain = domain
},
  SET_NEWCOMPANYDOMAIN(state,NewDomain){
    state.company.newCompanyDomain = NewDomain
},
  SET_UUID(state,pathUuid){
      state.company.Uuid = pathUuid
  },
  SET_IDENTITY_LINK(state,identityLink){
      state.company.identityLink = identityLink
  },
  SET_BUSINESS_TYPE(state,BusinessType){
    state.company.businessType = BusinessType
  },
  SET_SOLE_TRADER(state,soleTrader){
    state.company.soleTrader = soleTrader
  },
  SET_STATEMENT_ENABLED (state, statementEnabled) {
    state.company.statementEnabled = statementEnabled
  },
  SET_PORTAL_LIMITED_TO_ONE_COMPANY (state, isPortalLimitedToOneCompany) {
    state.company.isPortalLimitedToOneCompany = isPortalLimitedToOneCompany
  },
  SET_IS_END_USER_NOTIFICATIONS_ENABLED (state, isEndUserNotificationsEnabled) {
    state.company.isEndUserNotificationsEnabled = isEndUserNotificationsEnabled
  },
  SET_IS_OPTIONAL_MANUAL_COMPANY_NUMBER (state, optionalManualCompanyNumber) {
    state.company.optionalManualCompanyNumber = optionalManualCompanyNumber
  },
  SET_REQUIRED_ACTION(state, action) {
    if (Array.isArray(state.company.requiredCompanyActions) && !state.company.requiredCompanyActions.some(
      checkAction => checkAction.companyLinkCode === action.companyLinkCode)) {
      state.company.requiredCompanyActions.push(action)
    }
  },

  RESET_FIELD(state, value) {
    Object.keys(state.company).forEach((key) => {
        state.company.companyName = null
        state.company.address = null
        state.company.idStatus = false
        state.company.linkCode = ''
        state.company.role = ''
        state.company.customerReference = ''
        state.company.statement = ''
        state.company.companyDomain = ''
        state.company.newCompanyDomain = ''
        state.company.Uuid = ''
        state.company.identityLink = ''
        state.company.soleTrader = false
        state.company.businessType = 'company'
        state.company.requiredCompanyActions = []
        state.company.isPortalLimitedToOneCompany = false
        state.company.isEndUserNotificationsEnabled = false
        state.company.optionalManualCompanyNumber = false
    })
  },
}
