export default function ({ $axios, redirect, route, store }) {
    const slug = route.params.slug
    if (slug === null && localStorage.getItem("slug") === null) {
        $axios.onError(error => {
            if (error.response.status === 404 || error.response.status === 500) {
                return this.$nuxt.error({
                    statusCode: 404,
                    message: this.$t('portalLogin.portalLink'),
                })
            }
        })
    }
}