import clearLocalStorageMixin from '~/mixins/clearLocalStorageMixin'
export default async function ({ app, redirect, store, $auth, route }) {
  const isAuthenticated = !!store.state.auth.user
  const slug = localStorage.getItem('slug')
  if (!isAuthenticated) {
    return true
  }

  try {
    const response = await app.$axios.get(
      '/auth/enrich-link/' + slug
    )

    const userData = response.data.user

    if (userData.statement_enabled) {
      if (userData.has_company && userData.statement_declared) {
        redirect(`/portal/my-companies/${slug}`)
      } else {
        redirect(`/portal/find-company/${slug}/declaration`)
      }
    } else if (userData.soletrader_enabled) {
      redirect(`/portal/find-company/${slug}/select-business-type`)
    } else {
      redirect(`/portal/find-company/${slug}/find-company-account-created`)
    }
  } catch (error) {
    const redirectPath = `/portal/find-company/${localStorage.getItem('slug')}`
    const vuex = JSON.parse(localStorage.getItem('vuex'))
    if (isAuthenticated) {
      await $auth.logout()
    }
    store.commit('profile/RESET_FIELD', vuex?.profile?.company)
    clearLocalStorageMixin.methods.clearLocalStorageExceptKey('slug')
    if (route.name !== 'portal-find-company-slug') {
      redirect(redirectPath)
    }
  }
}

