
  import {
    mapGetters
  } from 'vuex'
  // import stepFindProgress from './stepFindProgress.vue'
  export default {
    components: {
      // stepFindProgress,
    },
    props: {
      showLogo: {
        type: Boolean,
        default: false,
      },
      showAddress: {
        type: Boolean,
        default: false,
      },
      className: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        urlpage: localStorage.getItem('actionPageUrl'),
        error: '',
        // isLoading: true
      }
    },
    computed: {
      ...mapGetters({
        company: 'profile/GET_COMPANY',
      }),
    },

    mounted() {
      this.checkSlugValue()
      this.fetch()

      this.applyStylesBasedOnOrientation()
      window.addEventListener('resize', this.applyStylesBasedOnOrientation)

      // setTimeout(() => {
      //   this.isLoading = false
      // }, 1000)
    },

    methods: {
      applyStylesBasedOnOrientation() {
        const image = this.$refs.companyLogo
        if (image && image.complete) {

          // Check image dimensions
          const width = image?.naturalWidth || image?.width
          const height = image?.naturalHeight || image?.height

          // Remove existing classes
          image.classList.remove('landscape', 'portrait')

          // Apply CSS class based on orientation
          if (width > height) {
            image.classList.add('landscape')
          } else {
            image.classList.add('portrait')
          }
          // this.isLoading = false
        }
      },

      checkSlugValue() {
        localStorage.setItem('slug', this.$route.params.slug)
        this.$store.dispatch('tenant/clear')

        const slug = localStorage.getItem('slug')
        const param = this.$route.params.slug

        if (!slug || param === "null" || slug === null) {
          localStorage.setItem('slug', this.$route.params.slug)
        }
      },

      async fetch() {
        /** logic for verifying link */
        const marketPlace = this.$route?.params?.slug ? this.$route.params.slug : ''
        const url = `/recaptcha/config/find-company/${marketPlace}`

        await this.$axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              // emit for login page blink
              this.$nuxt.$emit('loadSlug')
              this.$store.dispatch('profile/SET_LOGOURL', response.data.logo)
              this.$store.dispatch(
                'profile/SET_BG_COLOR',
                typeof response.data['insight-background-color']  === 'undefined' ? '#FCFCFC' : response.data['insight-background-color']
              )
              this.$store.dispatch(
                'profile/SET_TEXT_COLOR',
                 typeof response.data['insight-text-color'] === 'undefined' ? '#333333' : response.data['insight-text-color']
              )
              this.$store.dispatch(
                'profile/SET_CTA_COLOR',
              typeof response.data['insight-cta-color'] === 'undefined' ? '#333333' : response.data['insight-cta-color']
              )
              this.$store.dispatch('profile/SET_EMAIL', response.data.email)
              this.$nuxt.$emit('loadSlug')
              this.$store.dispatch('profile/SET_SOLE_TRADER', response.data.soletrader)
              this.$store.dispatch('profile/SET_PORTAL_LIMITED_TO_ONE_COMPANY', response.data.isPortalLimitedToOneCompany)
              this.$store.dispatch('profile/SET_IS_END_USER_NOTIFICATIONS_ENABLED', response.data.isEndUserNotificationsEnabled)
              this.$store.dispatch('profile/SET_IS_OPTIONAL_MANUAL_COMPANY_NUMBER', response.data.optionalManualCompanyNumber)
            }
          })
          .catch((error) => {
              // emit for login page blink
            this.$nuxt.$emit('loadSlug')
            if (error && error.response && error.response.status === 404) {
              const isAuth = !!this.$store.state.auth.user
              if(isAuth){
                this.$auth.logout()
              }
              console.clear()
              return this.$nuxt.error({
                statusCode: 404,
                message: this.$t('portalLogin.portalLinkInvalid'),
              })
            } else if (error && error.response && error.response.status === 429) {
              this.$notification.e(
                this.$t(error.response.data.message),
                this.$t('messages.error')
              )
            } else {
                this.$notification.e(
                this.$t('messages.defaultError'),
                this.$t('messages.error')
                )
            }
          })
      },
    },
  }

