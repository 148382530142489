export default {
    SET_PROGRESSBAR({commit},steps) {
        commit('SET_STEPS',steps)
    },

    SET_PAGETITLE({commit},pageTitle){
        commit('SET_TITLE',pageTitle)
    },

    UPDATE_CURRENTSTEP({commit},step) {
        commit('UPDATE_CURRENT_STEPINDEX',step)
    },

    UPDATE_STEPCOMPLETED({ commit }, obj) {
        commit('UPDATE_STEPCOMPLETED', obj)
    },

    UPDATE_VALUE({commit}, value) {
        commit('UPDATE_VALUE', value)
    }
}