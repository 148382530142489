export default {
    SET_SELECT_ENTITIES(state,selectEntities){
        state.selectEntities = selectEntities
    },
    SET_RESULT(state,result){
        state.Result = result
    },
    SET_RELATED_ENTITIES(state, relatedEntities) {
        state.relatedEntities = relatedEntities
    }
}