
  import {
    mapGetters,
    mapMutations
  } from 'vuex'

  export default {
    components: {
    },
    data() {
      return {
        isOpen: false,
        active: false,
        isSupport: false,
        isSuccess: false,
        isFAQ: false,
        // color:''
        langsCollection: ['en', 'fr'],
        isLinkCode: '',
        isLoading:true,
        // showLanguageDropdown: false,
      }
    },
    head() {
      return {
        // htmlAttrs: {
        //   lang: this.$i18n.locale
        // },
      }
    },
    computed: {
      ...mapGetters({
        company: 'profile/GET_COMPANY',
      }),
    },
    created() {
      clearInterval(Window.checkPersonIDVCompleted)
      clearInterval(Window.checkActionIDV)
      // this.selectedValue = this.$i18n.locale
      const linkCode = localStorage.getItem('linkCode')
      this.isLinkCode = linkCode
    },

    mounted() {
    // event bus get response status from MarketplaceLogoVue for login page blinking issue
      this.$nuxt.$on('loadSlug', () => {
        this.isLoading = false
      })
    },

    methods: {
      checkRoute() {
        const excludedRoutes = [
            'portal-find-company-slug-declaration',
            'portal-find-company-slug',
            'portal-find-company-slug-select-business-type',
            'portal-find-company-slug-find-company-account-created',
            'portal-find-company-slug-code',
            'portal-find-company-slug-customer-reference',
            'portal-my-companies-slug',
            'portal-find-company-path-slug-link',
            'portal-slug-code',
            'portal-declaratory-slug-code-complete-portal'
        ]

        return !excludedRoutes.includes(this.$route.name)
    },
      // changeLanguage() {
      //   this.$i18n.setLocale(this.selectedValue)
      //   this.$fetchLatestCustomTranslations(this.selectedValue)
      // },

      // updateLocale() {
      //   this.$i18n.setLocale(this.selectedValue)
      //   this.$fetchLatestCustomTranslations(this.selectedValue)
      // },

      toggle() {
        this.isOpen = !this.isOpen
        this.isSuccess = false
        this.isSupport = false
        this.isFAQ = false
        this.$emit('toggle')
      },

      backToMenu() {
        this.isOpen = true
        this.isFAQ = false
        this.isSupport = false
      },

      closeSucessToggle() {
        this.toggle()
      },

      goToFAQ() {
        const slug = localStorage.getItem('slug')
        this.$router.push(`/portal/find-company/${slug}/faq`)
        this.isOpen = false
      },

      goToLogin() {
        const slug = localStorage.getItem('slug')
        const url = '/portal/find-company/' + `${slug}` + '/login' + (this.company.customerReference ? '?reference=' +
          `${this.company.customerReference}` : '')
        this.$router.push(url)
        this.toggle()
      },

      openSucess() {
        this.isSuccess = true
        this.isSupport = false
        this.isFAQ = false
      },

      async logout() {
        const customerReference = this.company?.customerReference
        const slug = localStorage.getItem('slug')
        localStorage.clear()
        await this.$auth.logout()
        // localStorage.removeItem('vuex')
        // const findPortal = `/${this.$i18n.locale}/portal/find-company/${slug}`
        // window.location.href = findPortal
        const vuex = JSON.parse(localStorage.getItem('vuex'))
        if (this.company.role === 'identity') {
          this.$router.push(`/portal/find-company/${slug}/${this.company.linkCode}`)
        } else if (!this.company.hasCompany && customerReference && this.$route.name !== 'portal-find-company-slug-confirmcompanyadded' && !this.company.Uuid) {
          this.$router.push(`/portal/start-journey/${slug}/${customerReference}`)
        } else if (!this.company.hasCompany && customerReference && this.$route.name !== 'portal-find-company-slug-confirmcompanyadded' && this.company.Uuid){
          this.$router.push(`/portal/start-journey/${slug}/${customerReference}/${this.company.Uuid}`)
        } else if (this.company.Uuid && !customerReference){
          this.$router.push(`/portal/find-company-path/${slug}/${this.company.Uuid}`)
        } else {
          this.$router.push(`/portal/find-company/${slug}`)
        }

        this.$store.commit('profile/RESET_FIELD', vuex.profile.company)
        this.$store.commit('stepper/RESET_FIELD', vuex.stepper)
        this.toggle()
      },


      onChange(event) {
        this.$router.push(this.switchLocalePath(event))
      },
    }
  }

