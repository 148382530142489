export const state = () => ({
    // Progressbar for pagetitile and static bar
    progressBar: {
        nextStepIndex:0,
        currentStepIndex: 0,
        maxStepIndex: 5,
        pageTitle: 'Login',
    },
    // progressBar: [{
    //     stepCounter: 0,
    //     currentStepIndex: 0,
    //     maxStepIndex: 5,
    //     pageTitle: 'Login',
    //     completed: false,
    // }]
    // ProgressBar based on completed steps
    totalActions: 0,
    value: 0
})