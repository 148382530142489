import axios from 'axios'

export default function ({$axios, store, app}) {
  $axios.onRequest(async config => {

    const slug = localStorage.getItem('slug') ?? app.$route.params.slug

    // if already in store use that value, false means no header should be set
    if (store.getters["tenant/getHeader"] === false) {
      return config
    } else if (store.getters["tenant/getHeader"] !== null) {
      config.headers.common['X-Tenant'] = store.getters["tenant/getHeader"]
      return config
    }

    // fresh axios instance to bypass recursion of this plugin
    const axiosInstance = axios.create({
      baseURL: app.$config.profileAppBaseURL,
    })

    // fetch the tenant header and set it on subsequent requests
    try {
      const response = await axiosInstance({
        url: `public/tenancy/slug/${slug}`,
      })
      if (response.data.header) {
        store.commit('tenant/setHeader', response.data.header)
        config.headers.common['X-Tenant'] = response.data.header
      } else {
        // no specific tenant
        store.commit('tenant/setHeader', false)
      }
    } catch (error) {
      // do nothing
    }


    return config
  })
}
