export const state = () => ({
    selectcompany: {

    },
    selectLookupId: -1,
    selectCountryCode:'',
    manualCompany:{

    },
    searchedcompany:{

    }
})
