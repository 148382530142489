export default {
    methods: {
      clearLocalStorageExceptKey(exceptKey) {
        for (const key in localStorage) {
          if (key !== exceptKey) {
            localStorage.removeItem(key)
          }
        }
      }
    }
  }
