
import { mapGetters } from 'vuex'
import CookieConsent from '~/components/Common/CookieConsent.vue'
export default {
  components: {
    CookieConsent,
  },
  data() {
    return {
      show: true
    }
  },
  computed: {
    ...mapGetters({
      company: 'profile/GET_COMPANY',
    }),
  },
  methods: {
    toggleMenu(value) {
      this.show = !this.show
    },
  },
}
