export default {
    GET_INPROCESS_STEP: (state) => {
      return state.inProcessStep
    },
    GET_YOTIURL :(state) =>{
      return state.yotiIframeUrl
    },
    GET_QR :(state) =>{
      return state.QRCode
    },
    GET_TOKEN :(state) =>{
      return state.sessionToken
    },
    GET_USE_COMPUTER :(state) =>{
      return state.useComputer
    },
    GET_SELECT_PERSON :(state) =>{
      return state.selectPerson
    }
  }
