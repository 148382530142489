export default ({ store, $bugsnag, $auth, app, route }) => {

  store.subscribe((mutation, state) => {

    if (state.auth?.loggedIn && state.auth?.user) {
      const userInfo = state.auth.user

      try {

        const slug = route.params.slug || 'Not set'
        const { companyName, email } = userInfo || {}

        // Added default values for testing if the data is missing
        const safeSlug = slug || 'anonymous-Slug'
        const safeCompanyName = companyName || 'anonymous-company-name'
        const safeEmail = email || 'no-email@domain.com'

        // Set Bugsnag user
        $bugsnag.setUser(safeSlug, safeCompanyName, safeEmail)

        // Attach custom metadata including slug and company name (if available)
        $bugsnag.addMetadata('user', {
          slug: userInfo.slug,
          email: userInfo.email,
          companyName: userInfo.companyName || 'N/A' // Use 'N/A' if company name is not available
        })

      } catch (error) {
        console.error('Error setting user in Bugsnag:', error)
      }
    }
  })
}
