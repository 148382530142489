export default {
   
    SET_SELECTOWNERSHIP({commit},owner){
        commit('SET_SELECTOWNERSHIP',owner)
    },
    SET_RESULT({commit},result){
        commit('SET_RESULT',result)
    },
    SET_EDITOWNERSHIP({commit},editOwnership){
        commit('SET_EDITOWNERSHIP',editOwnership)
    },
    SET_BIRTH_COUNTRY_OWNER({commit}, birthCountryOwner){
        commit('SET_BIRTH_COUNTRY_OWNER', birthCountryOwner)
    }
   
}