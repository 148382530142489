export default {
    SET_SELECTOWNERSHIP(state,owner){
        state.selectOwnership = owner
    },
    SET_RESULT(state,result){
        state.Result = result
    },
    SET_EDITOWNERSHIP(state,editOwnership){
        state.editOwnership = editOwnership
    },
    SET_BIRTH_COUNTRY_OWNER(state,birthCountryOwner) {
        state.birthCountryOwner = birthCountryOwner
    }
  
    
}