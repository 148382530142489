import Vue from 'vue'
const _ = require("lodash")

export default ({ app, route }) => {
    const i18n = app.i18n
    let customTranslations = {}
    try {
        customTranslations = require('/locales/client/' + route.params.slug +'-'+ i18n.locale + '.json')
    }
    catch (e) {
        customTranslations = null
    }


    Vue.prototype.$t = (str, ...params) => {
      try {
          customTranslations = require('/locales/client/' + route.params.slug +'-'+ i18n.locale + '.json')
        }
        catch (e) {
            customTranslations = null
        }

        if (customTranslations != null) {
            const val = _.get(customTranslations, str)
            if (val) {
                return val
            } else {
                return i18n.t(str, ...params)
            }
        } else {
            return i18n.t(str, ...params)
        }
    }
}
