export default {
    GET_SELECTOWNERSHIP:(state)=>{
        return state.selectOwnership
    },
    GET_RESULT:(state)=>{
        return state.Result
    },
    GET_EDITOWNERSHIP:(state)=>{
        return state.editOwnership
    },
    GET_BIRTH_COUNTRY_OWNER:(state)=> {
        return state.birthCountryOwner
    }
   
}