
  import {
    mapGetters
  } from 'vuex'
  export default {
    layout: 'findportal',
    data() {
      return {

      }
    },
    computed: {
      ...mapGetters({
        company: 'profile/GET_COMPANY',
      }),
    },

    methods: {
      async logout() {
        const slug = localStorage.getItem('slug')
        localStorage.clear()
        await this.$auth.logout()
        this.$router.push(`/portal/find-company/${slug}`)
        this.$emit('closeToggle')
      },

      backToMainMenu() {
        this.$emit('backToMainMenu')
      }
    }
  }

