export default function ({ redirect, route, store }) {
  const isAuthenticated = !!store.state.auth.user
  const isStatementDeclared = !!localStorage.getItem('isDeclaration')
  const isStatementEnabled = !!store.state.profile.company.statement_enabled

  if (!isAuthenticated)
    return true

  if (isStatementEnabled && !isStatementDeclared) {
    redirect('/portal/find-company/' + localStorage.getItem('slug') + '/declaration')
  }
}