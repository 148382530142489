export default {
    GET_SELECT_ENTITIES:(state)=>{
        return state.selectEntities
    },
    GET_RESULT:(state)=>{
        return state.Result
    },
    GET_RELATED_ENTITIES: (state)=> {
        return state.relatedEntities
    }
}