
  import {
    mapGetters
  } from "vuex"
  import {
    required
  } from 'vuelidate/lib/validators'
  export default {
    data() {
      return {
        form: {
          message: ""
        },
        isMaxLength: false,
        word: 0,
        urlpage: localStorage.getItem('actionPageUrl'),
      }
    },
    validations: {
      form: {
        message: {
          required
        }
      }
    },
    computed: {
      ...mapGetters({
        company: "profile/GET_COMPANY"
      }),
    },
    methods: {
      wordLimit(e) {
        let text = document.getElementById("inputField").value
        const limit = 200
        text = text.split(" ")
        this.word = text.length
        if (this.word > limit) {
          this.isMaxLength = true
        } else {
          this.isMaxLength = false
        }
      },

      async sendMail() {
        const slug = localStorage.getItem('slug')
        const url = `recaptcha/find-company-portal/${slug}/send-mail`

        this.$v.$touch()
        if (!this.$v.$invalid) {
          try {
            this.form.email = this.company.supportEmail
            await this.$axios.post(url, this.form).then((response) => {
              if (response.status === 200) {
                this.$emit('sendEmail')
              }
            })

          } catch (error) {
            if (error && error.response && error.response.status === 404) {
              return this.$nuxt.error({
                statusCode: 404,
                message: this.$t('portalLogin.portalLink'),
              })
            } else {
              this.$notification.e(
                this.$t('messages.defaultError'),
                this.$t('messages.error')
              )
            }
          }
        }
      },

      async logout() {
        const slug = localStorage.getItem('slug')
        await this.$auth.logout()
        this.$router.push(`/portal/find-company/${slug}`)
        localStorage.clear()
        this.$emit('closeToggle')
      },

      backToMainMenu() {
        this.$emit('backToMainMenu')
      }
    }
  }

