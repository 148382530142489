export const state = () => ({
    additionalLocations: [],
    locationTypes: [{
        'title': 'additionalLocation.form.residential',
        'value': 'residential'
      },
      {
        'title': 'additionalLocation.form.commercial',
        'value': 'commercial'
      }
    ]
})