export default {
    SET_SELECTCOMPANY(state,company){
        state.selectcompany = company
    },
    SET_SELECTLOOKUP(state,id){
        state.selectLookupId = id
    },
    SET_SELECTCOUNTRY(state,country){
        state.selectCountryCode = country
    },
    SET_MANUALCOMPANY(state,manualCompany){
        state.manualCompany = manualCompany
    },
    SET_SEARCHCOMPANY(state,search){
        state.searchedcompany = search
    },

}
