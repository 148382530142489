export default {
    SET_SELECTCOMPANY({commit},company){
        commit('SET_SELECTCOMPANY',company)
    },
    SET_SELECTLOOKUP({commit},id){
        commit('SET_SELECTLOOKUP',id)
    },
    SET_SELECTCOUNTRY({commit},country){
        commit('SET_SELECTCOUNTRY',country)
    },
    SET_MANUALCOMPANY({commit},manualCompany){
        commit('SET_MANUALCOMPANY',manualCompany)
    },
    SET_SEARCHCOMPANY({commit},search){
        commit('SET_SEARCHCOMPANY',search)
    }
}
